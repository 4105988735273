import { Link } from "gatsby"
import Img from "gatsby-image"
import hazTouch from "haz-touch"
import React from "react"
import styles from "./grid-item-link-with-hover.module.scss"

export default class GridItemLinkWithHover extends React.Component {
  hasTouch() {
    return hazTouch ? styles.touch : styles.noTouch
  }
  render() {
    const { image, title, url } = this.props
    return (
      <div className={styles.item + " " + this.hasTouch()}>
        <Link to={url}>
          <h2 className="padding-s">{title}</h2>
          <Img fluid={image} />
        </Link>
      </div>
    )
  }
}
