// import "../styles/index.scss"

import { graphql } from "gatsby"
import React from "react"

import Footer from "../components/footer"
import GridItemLinkWithHover from "../components/grid-item-link-with-hover"
import Header from "../components/header"
import SEO from "../components/seo"
import GridItemWithHeading from "../components/grid-item-with-heading"

export default class ProductsTemplate extends React.Component {
  render() {
    const {
      title,
      image1,
      image2,
      image3,
      image4,
      image5,
      description,
      metaImage,
    } = {
      ...this.props.data.allMarkdownRemark.nodes[0].frontmatter,
    }
    return (
      <div>
        <SEO
          title={title}
          description={description}
          metaImage={metaImage}
          pathname={this.props.location.pathname}
        />
        <Header></Header>
        <main className="grid grid-col-1-2-3">
          <div className="grid-item-1x2">
            <GridItemWithHeading
              image={image1.childImageSharp.fluid}
              heading={title}
            ></GridItemWithHeading>
          </div>
          <div className="grid-item-1x1">
            <GridItemLinkWithHover
              image={image2.childImageSharp.fluid}
              title="Kitchens"
              url="/kitchens"
            ></GridItemLinkWithHover>
          </div>
          <div className="grid-item-1x1">
            <GridItemLinkWithHover
              image={image3.childImageSharp.fluid}
              title="Bathrooms"
              url="/bathrooms"
            ></GridItemLinkWithHover>
          </div>
          <div className="grid-item-1x1">
            <GridItemLinkWithHover
              image={image4.childImageSharp.fluid}
              title="Fires &amp; Fireplaces"
              url="/fires-and-fireplaces"
            ></GridItemLinkWithHover>
          </div>
          <div className="grid-item-1x1">
            <GridItemLinkWithHover
              image={image5.childImageSharp.fluid}
              title="Radiators"
              url="/radiators"
            ></GridItemLinkWithHover>
          </div>
        </main>
        <Footer></Footer>
      </div>
    )
  }
}

// font-size:8vw;
// TODO Seperate graphql file? Sharing images on other templates?

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "products" } } }
    ) {
      nodes {
        frontmatter {
          title
          description
          image1 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image2 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image3 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image4 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image5 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          metaImage {
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`
